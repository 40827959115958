:root {
  --primary-color: #afbcdb; /* //#3c7ec3; */
  --secondary-color: #173658;
  --highlight-color: rgb(254, 207, 167);
  --text-light: white;
  --text-dark: #161a1e
  --hover-bg: #ecedf0;
}

* {
  overflow-x: "hidden";
  box-sizing: border-box;
}

.h1 {
  margin-bottom: 30px;
  color: #3c7ec3;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color:  var(--secondary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  z-index: 1000;
}

.hero {
  margin-top: 60px; 
  padding: 20px;
  background-image: url("./assets/SantaMonica.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
}

.main {
  background-color: var(--primary-color); 
}
/* .p {
  text-align: center;
  justify-content: center;
  align-items: center;
} */

.btn {
  border-radius: 15px;
  border: 1px;
}

.table {
  width: 50%;
  margin-top: 20px;
}

.text-box {
  background-color: rgba(99, 102, 241, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 0.5rem; 
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.orange-text-box {
  background-color: rgba(254, 207, 167, 0.6); 
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 0.5rem; 
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.text-box p,
.orange-text-box p {
  color: var(--secondary-color);
  font-size: 22px; 
  /* 1rem; Default paragraph size */
  font-weight: 400; /* Standard readable weight */
  line-height: 1.6; /* Optimal for readability */
}

.text-box h2,
.orange-text-box h2 {
  color: var(--secondary-color);
  font-size: 26px; 
  /* 1rem; Default paragraph size */
  font-weight: 600; /* Standard readable weight */
  line-height: 1.6; /* Optimal for readability */
}

.card {
  position: relative; 
  overflow: hidden; 
  height: 700px;
  max-width: 20wh;
  min-width: 200px;
  border-radius: 15px;
  padding: 40px;
  background-color: #173658;
  color: white;
  &:hover {
    background-color: #ecedf0;
    color: rgb(49, 27, 177);
    box-shadow: 0 0 30px var(--secondary-color); 
  }

  h1 {
    font-size: 24px;
  }
}

.card * {
  position: relative;
  z-index: 1; /* Ensures text and content are above the overlay */
}

.card::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent 70%, var(--secondary-color) 100%);
  pointer-events: none;
  transition: background 0.3s ease-in-out;
  border-radius: inherit; /* Ensures it follows the card’s border-radius */
  z-index: 0;
}

.card:hover::before {
  background: linear-gradient(to bottom, transparent 70%, var(--highlight-color) 100%);
}

.wedding-card {
  background-image: url("./assets/forever.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.travel-card {
  background-image: url("./assets/Australia.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.gran-card {
  background-image: url("./assets/granny.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.multicolortext {
  background-image: linear-gradient(
    to right,
    #3961c6,
    #3c7ec3,
    #eb9b88,
    #e68619
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}
